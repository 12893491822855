import React, { useEffect, useState } from 'react';
import "@dnb/eufemia/style";
import { Accounts } from '../components/Accounts';
import Layout from "../components/layout";

const DashboardPage = () => {
    return(
        <Layout>
          <Accounts />
        </Layout>
    )
}

export default DashboardPage;