import React, { useEffect, useState } from "react";
import {P, Table} from "@dnb/eufemia"
import { CustomButton } from '../components/styledComponents';
import axios from 'axios';
import { navigate } from "gatsby";

const API = process.env.API_ENDPOINT

// Request interceptors for API calls
axios.interceptors.request.use(
  config => {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

type Portfolio = {
    assets: Array<Position>
  }
  
  type Position = {
      asset: string
      amount: number
  }

  const getPortfolio = (): Promise<Portfolio> => {
    return axios
      .get(`${API}/portfolio`)
      .then((res) => {
        return res.data
      })
      .catch((err) => {
        console.log('error in request:', err);
      })

  }  

export const Accounts = () => {

    const [errors, setErrors] = useState<Array<string>>([]);
    const [positions, setPositions] = useState<undefined | Portfolio>(undefined);

    useEffect(() => {
        getPortfolio()
            .then(items => {
              setPositions(items)
            })
            .catch((err) => {
              setErrors(["Unable to load assets"])
            })
        }, [])

    return <>
        <h1>Your Accounts</h1>
        { errors ?? <div>Encountered error</div> }  
        <Table>
            <thead>
                <tr>
                  <th>Asset</th>
                  <th>Amount</th>
                </tr>
            </thead>
            <tbody>
            { positions && positions.assets.map((item) => {
              return (<tr><td>{item.asset}</td><td>{item.amount}</td></tr>)
            })}
            </tbody>
        </Table>
        <P>
          Looking to buy? &nbsp;
         <CustomButton onClick={() => navigate(`/buy`)}>Buy</CustomButton>        
        </P>
        
    </>
}